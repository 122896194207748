import { useEffect, useMemo } from 'react';
import cl from 'classnames';
import map from 'lodash/map';
import find from 'lodash/find';
import compact from 'lodash/compact';

import { TeamNanoID } from '../../teamsTypes';
import { ProjectNanoID } from '../../../projects/projectsTypes';
import { GeneralLedgerGeneralLedgerTypes } from '../../../generalLedgers/generalLedgersTypes';
import { ClassName, Currencies } from '../../../../types';
import { CompanyPaymentsGeneralLedgerType } from './CompanyPaymentsGeneralLedgersFilter.types';

import {
  FETCH_GENERAL_LEDGERS_QUERY,
  FetchGeneralLedgersQueryResponse
} from '../../../generalLedgers/queries/fetchGeneralLedgers.query';

import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import { useTranslate } from '../../../../common/hooks/useTranslate';
import { useFinPaginatedGeneralLedgers } from '../../../generalLedgers/hooks/useFinPaginatedGeneralLedgers';
import { useReactQueryState } from '../../../common/hooks/base/reactQuery/useReactQueryState';

import { PureIconBadgeButtonHelper } from '../../../../helpers/buttons/PureIconBadgeButtonHelper';
import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';

import { GeneralLedgerCache } from '../../../generalLedgers/GeneralLedgerCache';
import { companyPaymentsMenuGeneralLedgerKey } from '../../teamsConstants';
import { words } from '../../../../locales/keys';

interface CompanyPaymentsGeneralLedgersFilterProps {
  className?: ClassName;
  companyNanoId: TeamNanoID;
  projectNanoId?: ProjectNanoID;
  withItemsAwaitedTotal?: boolean;
  hideZeroBalances?: boolean;
  buttonBaseClassName?: ClassName;
  buttonActiveClassName?: ClassName;
  buttonDefaultClassName?: ClassName;
}

function CompanyPaymentsGeneralLedgersFilter({
  className,
  companyNanoId,
  projectNanoId,
  withItemsAwaitedTotal,
  hideZeroBalances,
  buttonBaseClassName,
  buttonActiveClassName,
  buttonDefaultClassName
}: CompanyPaymentsGeneralLedgersFilterProps) {
  const { t } = useTranslate();

  const initialFilters = useMemo(
    () => ({
      companyNanoId: { eq: companyNanoId }
    }),
    [companyNanoId]
  );

  const additionalParams = useMemo(
    () => ({
      convertTo: Currencies.USD.toLocaleLowerCase(),
      itemsAvaitedFilters: {
        projectNanoId: projectNanoId ? { eq: projectNanoId } : undefined
      }
    }),
    [projectNanoId]
  );

  const { generalLedgers, generalLedgersFetched, filterGeneralLedgers } =
    useFinPaginatedGeneralLedgers<FetchGeneralLedgersQueryResponse>({
      cacheKey: GeneralLedgerCache.companyGeneralLedgersCacheKey(companyNanoId),
      query: FETCH_GENERAL_LEDGERS_QUERY,
      initialFilters,
      additionalParams,
      options: {
        enabled: !!companyNanoId,
        enabledPlaceholder: !!companyNanoId
      }
    });

  const prevFilters = usePreviousValue(initialFilters);

  useEffect(() => {
    if (prevFilters !== initialFilters) {
      filterGeneralLedgers(initialFilters);
    }
  }, [prevFilters, filterGeneralLedgers, initialFilters]);

  const { setValue, value } =
    useReactQueryState<CompanyPaymentsGeneralLedgerType>(
      companyPaymentsMenuGeneralLedgerKey(companyNanoId)
    );

  useEffect(() => {
    const defaultGl = find(
      generalLedgers,
      (item) =>
        item.generalLedgerType === GeneralLedgerGeneralLedgerTypes.DEFAULT
    );

    !value && defaultGl && setValue(defaultGl);

    console.log('UPDATE GL');
  }, [generalLedgers, initialFilters, setValue, value]);

  useEffect(() => {
    return () => {
      console.log('RESET VALUE');
      setValue(undefined);
    };
  }, [setValue]);

  const glsWithItemsTotals = useMemo(
    () =>
      compact(
        map(generalLedgers, (gl) => {
          const calculatedBalance =
            (withItemsAwaitedTotal
              ? gl.itemsAwaitedTotal?.convertedAmount
              : gl.cashAccount?.balance) || 0;

          if (hideZeroBalances && calculatedBalance === 0) {
            return null;
          }

          return {
            ...gl,
            calculatedBalance
          };
        })
      ),
    [generalLedgers, withItemsAwaitedTotal, hideZeroBalances]
  );

  return (
    <LoadingSkeleton
      className={className}
      skeletonClassName="flex flex-wrap gap-1 w-full"
      itemClassName="w-32 h-9 rounded-full"
      loaded={generalLedgersFetched}
      count={3}
    >
      <div className={className || 'flex flex-wrap'}>
        {map(glsWithItemsTotals, (item) => (
          <PureIconBadgeButtonHelper
            key={item.id}
            className={cl(
              buttonBaseClassName ||
                'rounded-full flex items-center space-x-2 py-1.5 px-4 text-sm font-medium border focus:ring-offset-0 m-0.5',
              item.id === value?.id
                ? buttonActiveClassName ||
                    'bg-blue-500 bg-opacity-20 text-blue-600 dark:text-blue-400 border-transparent'
                : buttonDefaultClassName ||
                    'dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-800'
            )}
            text={
              item.generalLedgerType === GeneralLedgerGeneralLedgerTypes.DEFAULT
                ? t(words.default)
                : item.name
            }
            badgeValue={item.calculatedBalance}
            badgeClassName="border-l opacity-70 border-current ml-2 pl-2 font-normal text-xs"
            badgePrefix="$"
            onClick={() => setValue(item)}
          />
        ))}
      </div>
    </LoadingSkeleton>
  );
}

export default CompanyPaymentsGeneralLedgersFilter;
